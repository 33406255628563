/**
 * Generated by orval 🍺
 * Do not edit manually.
 * api API
 * OpenAPI spec version: 1.0
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryClient,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type { AggregationMetadataLookupDTO } from '../models/aggregationMetadataLookupDTO';
import type { Application } from '../models/application';
import type { ExcelFormulaHits } from '../models/excelFormulaHits';
import type { FindExcelFormulaSuggestionsParams } from '../models/findExcelFormulaSuggestionsParams';
import type { FindNodeBuilderResourcesRequest } from '../models/findNodeBuilderResourcesRequest';
import type { FindResourcesForNodeParams } from '../models/findResourcesForNodeParams';
import type { GetCollectSlotSchemaBody } from '../models/getCollectSlotSchemaBody';
import type { GetNodesParams } from '../models/getNodesParams';
import type { GetWebhookTriggerUrlParams } from '../models/getWebhookTriggerUrlParams';
import type { GetWebhookUrlParams } from '../models/getWebhookUrlParams';
import type { LookupNodesOutputSchemaBody } from '../models/lookupNodesOutputSchemaBody';
import type { NodeAutoMapRequest } from '../models/nodeAutoMapRequest';
import type { NodeAutoMapResponse } from '../models/nodeAutoMapResponse';
import type { NodeBuilder } from '../models/nodeBuilder';
import type { NodeBuilderHits } from '../models/nodeBuilderHits';
import type { NodeBuilderMap } from '../models/nodeBuilderMap';
import type { NodeBuilderResource } from '../models/nodeBuilderResource';
import type { NodeBuilderResourceHits } from '../models/nodeBuilderResourceHits';
import type { NodeBuilderResourceListMap } from '../models/nodeBuilderResourceListMap';
import type { NodeBuilderResourceMap } from '../models/nodeBuilderResourceMap';
import type { NodeSchemaLookupDTO } from '../models/nodeSchemaLookupDTO';
import type { Schema } from '../models/schema';
import type { SchemaLookupDTO } from '../models/schemaLookupDTO';
import type { SchemaMap } from '../models/schemaMap';
import type { StringObjectMap } from '../models/stringObjectMap';
import { executeFetch } from '../../utils/fetch/fetch';
import type { ErrorType } from '../../utils/fetch/fetch';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getCollectSlotSchema = (
  getCollectSlotSchemaBody: GetCollectSlotSchemaBody,
  options?: SecondParameter<typeof executeFetch>,
) => {
  return executeFetch<Schema>(
    {
      url: `/api/workflow-builder/collect-slot-schema`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: getCollectSlotSchemaBody,
    },
    options,
  );
};

export const getGetCollectSlotSchemaQueryKey = (
  getCollectSlotSchemaBody: GetCollectSlotSchemaBody,
) => {
  return [`/api/workflow-builder/collect-slot-schema`, getCollectSlotSchemaBody] as const;
};

export const getGetCollectSlotSchemaQueryOptions = <
  TData = Awaited<ReturnType<typeof getCollectSlotSchema>>,
  TError = ErrorType<unknown>,
>(
  getCollectSlotSchemaBody: GetCollectSlotSchemaBody,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getCollectSlotSchema>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCollectSlotSchemaQueryKey(getCollectSlotSchemaBody);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectSlotSchema>>> = () =>
    getCollectSlotSchema(getCollectSlotSchemaBody, requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getCollectSlotSchema>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCollectSlotSchemaQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCollectSlotSchema>>
>;
export type GetCollectSlotSchemaQueryError = ErrorType<unknown>;

export const useGetCollectSlotSchema = <
  TData = Awaited<ReturnType<typeof getCollectSlotSchema>>,
  TError = ErrorType<unknown>,
>(
  getCollectSlotSchemaBody: GetCollectSlotSchemaBody,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getCollectSlotSchema>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCollectSlotSchemaQueryOptions(getCollectSlotSchemaBody, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const prefetchGetCollectSlotSchema = async <
  TData = Awaited<ReturnType<typeof getCollectSlotSchema>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  getCollectSlotSchemaBody: GetCollectSlotSchemaBody,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getCollectSlotSchema>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getGetCollectSlotSchemaQueryOptions(getCollectSlotSchemaBody, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const findExcelFormulaSuggestions = (
  params?: FindExcelFormulaSuggestionsParams,
  options?: SecondParameter<typeof executeFetch>,
) => {
  return executeFetch<ExcelFormulaHits>(
    { url: `/api/workflow-builder/excel-formula/suggestions`, method: 'POST', params },
    options,
  );
};

export const getFindExcelFormulaSuggestionsQueryKey = (
  params?: FindExcelFormulaSuggestionsParams,
) => {
  return [`/api/workflow-builder/excel-formula/suggestions`, ...(params ? [params] : [])] as const;
};

export const getFindExcelFormulaSuggestionsQueryOptions = <
  TData = Awaited<ReturnType<typeof findExcelFormulaSuggestions>>,
  TError = ErrorType<unknown>,
>(
  params?: FindExcelFormulaSuggestionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof findExcelFormulaSuggestions>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getFindExcelFormulaSuggestionsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof findExcelFormulaSuggestions>>> = () =>
    findExcelFormulaSuggestions(params, requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof findExcelFormulaSuggestions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FindExcelFormulaSuggestionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof findExcelFormulaSuggestions>>
>;
export type FindExcelFormulaSuggestionsQueryError = ErrorType<unknown>;

export const useFindExcelFormulaSuggestions = <
  TData = Awaited<ReturnType<typeof findExcelFormulaSuggestions>>,
  TError = ErrorType<unknown>,
>(
  params?: FindExcelFormulaSuggestionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof findExcelFormulaSuggestions>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFindExcelFormulaSuggestionsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const prefetchFindExcelFormulaSuggestions = async <
  TData = Awaited<ReturnType<typeof findExcelFormulaSuggestions>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  params?: FindExcelFormulaSuggestionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof findExcelFormulaSuggestions>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getFindExcelFormulaSuggestionsQueryOptions(params, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const listenForWebhookRequestSchema = (
  workflowId: string,
  options?: SecondParameter<typeof executeFetch>,
  signal?: AbortSignal,
) => {
  return executeFetch<StringObjectMap>(
    {
      url: `/api/workflow-builder/listen-for-webhook-request-schema/${encodeURIComponent(String(workflowId))}`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getListenForWebhookRequestSchemaQueryKey = (workflowId: string) => {
  return [`/api/workflow-builder/listen-for-webhook-request-schema/${workflowId}`] as const;
};

export const getListenForWebhookRequestSchemaQueryOptions = <
  TData = Awaited<ReturnType<typeof listenForWebhookRequestSchema>>,
  TError = ErrorType<unknown>,
>(
  workflowId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof listenForWebhookRequestSchema>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListenForWebhookRequestSchemaQueryKey(workflowId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listenForWebhookRequestSchema>>> = ({
    signal,
  }) => listenForWebhookRequestSchema(workflowId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!workflowId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listenForWebhookRequestSchema>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListenForWebhookRequestSchemaQueryResult = NonNullable<
  Awaited<ReturnType<typeof listenForWebhookRequestSchema>>
>;
export type ListenForWebhookRequestSchemaQueryError = ErrorType<unknown>;

export const useListenForWebhookRequestSchema = <
  TData = Awaited<ReturnType<typeof listenForWebhookRequestSchema>>,
  TError = ErrorType<unknown>,
>(
  workflowId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof listenForWebhookRequestSchema>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListenForWebhookRequestSchemaQueryOptions(workflowId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const prefetchListenForWebhookRequestSchema = async <
  TData = Awaited<ReturnType<typeof listenForWebhookRequestSchema>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  workflowId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof listenForWebhookRequestSchema>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getListenForWebhookRequestSchemaQueryOptions(workflowId, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const lookupAlertVariablesSchema = (
  options?: SecondParameter<typeof executeFetch>,
  signal?: AbortSignal,
) => {
  return executeFetch<Schema>(
    { url: `/api/workflow-builder/lookup/alert-variables-schema`, method: 'GET', signal },
    options,
  );
};

export const getLookupAlertVariablesSchemaQueryKey = () => {
  return [`/api/workflow-builder/lookup/alert-variables-schema`] as const;
};

export const getLookupAlertVariablesSchemaQueryOptions = <
  TData = Awaited<ReturnType<typeof lookupAlertVariablesSchema>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof lookupAlertVariablesSchema>>, TError, TData>
  >;
  request?: SecondParameter<typeof executeFetch>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getLookupAlertVariablesSchemaQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof lookupAlertVariablesSchema>>> = ({
    signal,
  }) => lookupAlertVariablesSchema(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof lookupAlertVariablesSchema>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type LookupAlertVariablesSchemaQueryResult = NonNullable<
  Awaited<ReturnType<typeof lookupAlertVariablesSchema>>
>;
export type LookupAlertVariablesSchemaQueryError = ErrorType<unknown>;

export const useLookupAlertVariablesSchema = <
  TData = Awaited<ReturnType<typeof lookupAlertVariablesSchema>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof lookupAlertVariablesSchema>>, TError, TData>
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getLookupAlertVariablesSchemaQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const prefetchLookupAlertVariablesSchema = async <
  TData = Awaited<ReturnType<typeof lookupAlertVariablesSchema>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof lookupAlertVariablesSchema>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getLookupAlertVariablesSchemaQueryOptions(options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const lookupApplications = (
  workflowId: string,
  options?: SecondParameter<typeof executeFetch>,
  signal?: AbortSignal,
) => {
  return executeFetch<Application[]>(
    {
      url: `/api/workflow-builder/lookup/applications/${encodeURIComponent(String(workflowId))}`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getLookupApplicationsQueryKey = (workflowId: string) => {
  return [`/api/workflow-builder/lookup/applications/${workflowId}`] as const;
};

export const getLookupApplicationsQueryOptions = <
  TData = Awaited<ReturnType<typeof lookupApplications>>,
  TError = ErrorType<unknown>,
>(
  workflowId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof lookupApplications>>, TError, TData>>;
    request?: SecondParameter<typeof executeFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getLookupApplicationsQueryKey(workflowId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof lookupApplications>>> = ({ signal }) =>
    lookupApplications(workflowId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!workflowId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof lookupApplications>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type LookupApplicationsQueryResult = NonNullable<
  Awaited<ReturnType<typeof lookupApplications>>
>;
export type LookupApplicationsQueryError = ErrorType<unknown>;

export const useLookupApplications = <
  TData = Awaited<ReturnType<typeof lookupApplications>>,
  TError = ErrorType<unknown>,
>(
  workflowId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof lookupApplications>>, TError, TData>>;
    request?: SecondParameter<typeof executeFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getLookupApplicationsQueryOptions(workflowId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const prefetchLookupApplications = async <
  TData = Awaited<ReturnType<typeof lookupApplications>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  workflowId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof lookupApplications>>, TError, TData>>;
    request?: SecondParameter<typeof executeFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getLookupApplicationsQueryOptions(workflowId, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const lookupEnvVariablesSchema = (options?: SecondParameter<typeof executeFetch>) => {
  return executeFetch<Schema>(
    { url: `/api/workflow-builder/lookup/env-variables-schema`, method: 'GET' },
    options,
  );
};

export const getLookupEnvVariablesSchemaQueryKey = () => {
  return [`/api/workflow-builder/lookup/env-variables-schema`] as const;
};

export const getLookupEnvVariablesSchemaQueryOptions = <
  TData = Awaited<ReturnType<typeof lookupEnvVariablesSchema>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof lookupEnvVariablesSchema>>, TError, TData>
  >;
  request?: SecondParameter<typeof executeFetch>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getLookupEnvVariablesSchemaQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof lookupEnvVariablesSchema>>> = () =>
    lookupEnvVariablesSchema(requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof lookupEnvVariablesSchema>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type LookupEnvVariablesSchemaQueryResult = NonNullable<
  Awaited<ReturnType<typeof lookupEnvVariablesSchema>>
>;
export type LookupEnvVariablesSchemaQueryError = ErrorType<unknown>;

export const useLookupEnvVariablesSchema = <
  TData = Awaited<ReturnType<typeof lookupEnvVariablesSchema>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof lookupEnvVariablesSchema>>, TError, TData>
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getLookupEnvVariablesSchemaQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const prefetchLookupEnvVariablesSchema = async <
  TData = Awaited<ReturnType<typeof lookupEnvVariablesSchema>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof lookupEnvVariablesSchema>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getLookupEnvVariablesSchemaQueryOptions(options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const lookupErrorSchema = (
  schemaLookupDTO: SchemaLookupDTO,
  options?: SecondParameter<typeof executeFetch>,
) => {
  return executeFetch<SchemaMap>(
    {
      url: `/api/workflow-builder/lookup/error-schema`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: schemaLookupDTO,
    },
    options,
  );
};

export const getLookupErrorSchemaMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof lookupErrorSchema>>,
    TError,
    { data: SchemaLookupDTO },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof lookupErrorSchema>>,
  TError,
  { data: SchemaLookupDTO },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof lookupErrorSchema>>,
    { data: SchemaLookupDTO }
  > = (props) => {
    const { data } = props ?? {};

    return lookupErrorSchema(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type LookupErrorSchemaMutationResult = NonNullable<
  Awaited<ReturnType<typeof lookupErrorSchema>>
>;
export type LookupErrorSchemaMutationBody = SchemaLookupDTO;
export type LookupErrorSchemaMutationError = ErrorType<unknown>;

export const useLookupErrorSchema = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof lookupErrorSchema>>,
    TError,
    { data: SchemaLookupDTO },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof lookupErrorSchema>>,
  TError,
  { data: SchemaLookupDTO },
  TContext
> => {
  const mutationOptions = getLookupErrorSchemaMutationOptions(options);

  return useMutation(mutationOptions);
};
export const lookupErrorSchemaByWorkflowId = (
  workflowId: string,
  lookupErrorSchemaByWorkflowIdBody: string[],
  options?: SecondParameter<typeof executeFetch>,
) => {
  return executeFetch<SchemaMap>(
    {
      url: `/api/workflow-builder/lookup/error-schema/${encodeURIComponent(String(workflowId))}`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: lookupErrorSchemaByWorkflowIdBody,
    },
    options,
  );
};

export const getLookupErrorSchemaByWorkflowIdMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof lookupErrorSchemaByWorkflowId>>,
    TError,
    { workflowId: string; data: string[] },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof lookupErrorSchemaByWorkflowId>>,
  TError,
  { workflowId: string; data: string[] },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof lookupErrorSchemaByWorkflowId>>,
    { workflowId: string; data: string[] }
  > = (props) => {
    const { workflowId, data } = props ?? {};

    return lookupErrorSchemaByWorkflowId(workflowId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type LookupErrorSchemaByWorkflowIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof lookupErrorSchemaByWorkflowId>>
>;
export type LookupErrorSchemaByWorkflowIdMutationBody = string[];
export type LookupErrorSchemaByWorkflowIdMutationError = ErrorType<unknown>;

export const useLookupErrorSchemaByWorkflowId = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof lookupErrorSchemaByWorkflowId>>,
    TError,
    { workflowId: string; data: string[] },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof lookupErrorSchemaByWorkflowId>>,
  TError,
  { workflowId: string; data: string[] },
  TContext
> => {
  const mutationOptions = getLookupErrorSchemaByWorkflowIdMutationOptions(options);

  return useMutation(mutationOptions);
};
export const lookupInputSchema = (
  schemaLookupDTO: SchemaLookupDTO,
  options?: SecondParameter<typeof executeFetch>,
) => {
  return executeFetch<SchemaMap>(
    {
      url: `/api/workflow-builder/lookup/input-schema`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: schemaLookupDTO,
    },
    options,
  );
};

export const getLookupInputSchemaMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof lookupInputSchema>>,
    TError,
    { data: SchemaLookupDTO },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof lookupInputSchema>>,
  TError,
  { data: SchemaLookupDTO },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof lookupInputSchema>>,
    { data: SchemaLookupDTO }
  > = (props) => {
    const { data } = props ?? {};

    return lookupInputSchema(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type LookupInputSchemaMutationResult = NonNullable<
  Awaited<ReturnType<typeof lookupInputSchema>>
>;
export type LookupInputSchemaMutationBody = SchemaLookupDTO;
export type LookupInputSchemaMutationError = ErrorType<unknown>;

export const useLookupInputSchema = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof lookupInputSchema>>,
    TError,
    { data: SchemaLookupDTO },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof lookupInputSchema>>,
  TError,
  { data: SchemaLookupDTO },
  TContext
> => {
  const mutationOptions = getLookupInputSchemaMutationOptions(options);

  return useMutation(mutationOptions);
};
export const lookupNodeAggregationMetadata = (
  aggregationMetadataLookupDTO: AggregationMetadataLookupDTO,
  options?: SecondParameter<typeof executeFetch>,
) => {
  return executeFetch<void>(
    {
      url: `/api/workflow-builder/lookup/node/aggregation-metadata`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: aggregationMetadataLookupDTO,
    },
    options,
  );
};

export const getLookupNodeAggregationMetadataQueryKey = (
  aggregationMetadataLookupDTO: AggregationMetadataLookupDTO,
) => {
  return [
    `/api/workflow-builder/lookup/node/aggregation-metadata`,
    aggregationMetadataLookupDTO,
  ] as const;
};

export const getLookupNodeAggregationMetadataQueryOptions = <
  TData = Awaited<ReturnType<typeof lookupNodeAggregationMetadata>>,
  TError = ErrorType<unknown>,
>(
  aggregationMetadataLookupDTO: AggregationMetadataLookupDTO,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof lookupNodeAggregationMetadata>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getLookupNodeAggregationMetadataQueryKey(aggregationMetadataLookupDTO);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof lookupNodeAggregationMetadata>>> = () =>
    lookupNodeAggregationMetadata(aggregationMetadataLookupDTO, requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof lookupNodeAggregationMetadata>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type LookupNodeAggregationMetadataQueryResult = NonNullable<
  Awaited<ReturnType<typeof lookupNodeAggregationMetadata>>
>;
export type LookupNodeAggregationMetadataQueryError = ErrorType<unknown>;

export const useLookupNodeAggregationMetadata = <
  TData = Awaited<ReturnType<typeof lookupNodeAggregationMetadata>>,
  TError = ErrorType<unknown>,
>(
  aggregationMetadataLookupDTO: AggregationMetadataLookupDTO,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof lookupNodeAggregationMetadata>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getLookupNodeAggregationMetadataQueryOptions(
    aggregationMetadataLookupDTO,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const prefetchLookupNodeAggregationMetadata = async <
  TData = Awaited<ReturnType<typeof lookupNodeAggregationMetadata>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  aggregationMetadataLookupDTO: AggregationMetadataLookupDTO,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof lookupNodeAggregationMetadata>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getLookupNodeAggregationMetadataQueryOptions(
    aggregationMetadataLookupDTO,
    options,
  );

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const lookupNodeOutputSchema = (
  nodeSchemaLookupDTO: NodeSchemaLookupDTO,
  options?: SecondParameter<typeof executeFetch>,
) => {
  return executeFetch<Schema>(
    {
      url: `/api/workflow-builder/lookup/node/output-schema`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: nodeSchemaLookupDTO,
    },
    options,
  );
};

export const getLookupNodeOutputSchemaQueryKey = (nodeSchemaLookupDTO: NodeSchemaLookupDTO) => {
  return [`/api/workflow-builder/lookup/node/output-schema`, nodeSchemaLookupDTO] as const;
};

export const getLookupNodeOutputSchemaQueryOptions = <
  TData = Awaited<ReturnType<typeof lookupNodeOutputSchema>>,
  TError = ErrorType<unknown>,
>(
  nodeSchemaLookupDTO: NodeSchemaLookupDTO,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof lookupNodeOutputSchema>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getLookupNodeOutputSchemaQueryKey(nodeSchemaLookupDTO);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof lookupNodeOutputSchema>>> = () =>
    lookupNodeOutputSchema(nodeSchemaLookupDTO, requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof lookupNodeOutputSchema>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type LookupNodeOutputSchemaQueryResult = NonNullable<
  Awaited<ReturnType<typeof lookupNodeOutputSchema>>
>;
export type LookupNodeOutputSchemaQueryError = ErrorType<unknown>;

export const useLookupNodeOutputSchema = <
  TData = Awaited<ReturnType<typeof lookupNodeOutputSchema>>,
  TError = ErrorType<unknown>,
>(
  nodeSchemaLookupDTO: NodeSchemaLookupDTO,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof lookupNodeOutputSchema>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getLookupNodeOutputSchemaQueryOptions(nodeSchemaLookupDTO, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const prefetchLookupNodeOutputSchema = async <
  TData = Awaited<ReturnType<typeof lookupNodeOutputSchema>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  nodeSchemaLookupDTO: NodeSchemaLookupDTO,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof lookupNodeOutputSchema>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getLookupNodeOutputSchemaQueryOptions(nodeSchemaLookupDTO, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const lookupNodes = (
  lookupNodesBody: string[],
  options?: SecondParameter<typeof executeFetch>,
) => {
  return executeFetch<NodeBuilderMap>(
    {
      url: `/api/workflow-builder/lookup/nodes`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: lookupNodesBody,
    },
    options,
  );
};

export const getLookupNodesMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof lookupNodes>>,
    TError,
    { data: string[] },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof lookupNodes>>,
  TError,
  { data: string[] },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof lookupNodes>>,
    { data: string[] }
  > = (props) => {
    const { data } = props ?? {};

    return lookupNodes(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type LookupNodesMutationResult = NonNullable<Awaited<ReturnType<typeof lookupNodes>>>;
export type LookupNodesMutationBody = string[];
export type LookupNodesMutationError = ErrorType<unknown>;

export const useLookupNodes = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof lookupNodes>>,
    TError,
    { data: string[] },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof lookupNodes>>,
  TError,
  { data: string[] },
  TContext
> => {
  const mutationOptions = getLookupNodesMutationOptions(options);

  return useMutation(mutationOptions);
};
export const lookupNodesOutputSchema = (
  lookupNodesOutputSchemaBody: LookupNodesOutputSchemaBody,
  options?: SecondParameter<typeof executeFetch>,
) => {
  return executeFetch<SchemaMap>(
    {
      url: `/api/workflow-builder/lookup/nodes/output-schema`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: lookupNodesOutputSchemaBody,
    },
    options,
  );
};

export const getLookupNodesOutputSchemaQueryKey = (
  lookupNodesOutputSchemaBody: LookupNodesOutputSchemaBody,
) => {
  return [`/api/workflow-builder/lookup/nodes/output-schema`, lookupNodesOutputSchemaBody] as const;
};

export const getLookupNodesOutputSchemaQueryOptions = <
  TData = Awaited<ReturnType<typeof lookupNodesOutputSchema>>,
  TError = ErrorType<unknown>,
>(
  lookupNodesOutputSchemaBody: LookupNodesOutputSchemaBody,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof lookupNodesOutputSchema>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getLookupNodesOutputSchemaQueryKey(lookupNodesOutputSchemaBody);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof lookupNodesOutputSchema>>> = () =>
    lookupNodesOutputSchema(lookupNodesOutputSchemaBody, requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof lookupNodesOutputSchema>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type LookupNodesOutputSchemaQueryResult = NonNullable<
  Awaited<ReturnType<typeof lookupNodesOutputSchema>>
>;
export type LookupNodesOutputSchemaQueryError = ErrorType<unknown>;

export const useLookupNodesOutputSchema = <
  TData = Awaited<ReturnType<typeof lookupNodesOutputSchema>>,
  TError = ErrorType<unknown>,
>(
  lookupNodesOutputSchemaBody: LookupNodesOutputSchemaBody,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof lookupNodesOutputSchema>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getLookupNodesOutputSchemaQueryOptions(lookupNodesOutputSchemaBody, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const prefetchLookupNodesOutputSchema = async <
  TData = Awaited<ReturnType<typeof lookupNodesOutputSchema>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  lookupNodesOutputSchemaBody: LookupNodesOutputSchemaBody,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof lookupNodesOutputSchema>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getLookupNodesOutputSchemaQueryOptions(lookupNodesOutputSchemaBody, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const lookupOutputSchema = (
  schemaLookupDTO: SchemaLookupDTO,
  options?: SecondParameter<typeof executeFetch>,
) => {
  return executeFetch<SchemaMap>(
    {
      url: `/api/workflow-builder/lookup/output-schema`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: schemaLookupDTO,
    },
    options,
  );
};

export const getLookupOutputSchemaQueryKey = (schemaLookupDTO: SchemaLookupDTO) => {
  return [`/api/workflow-builder/lookup/output-schema`, schemaLookupDTO] as const;
};

export const getLookupOutputSchemaQueryOptions = <
  TData = Awaited<ReturnType<typeof lookupOutputSchema>>,
  TError = ErrorType<unknown>,
>(
  schemaLookupDTO: SchemaLookupDTO,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof lookupOutputSchema>>, TError, TData>>;
    request?: SecondParameter<typeof executeFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getLookupOutputSchemaQueryKey(schemaLookupDTO);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof lookupOutputSchema>>> = () =>
    lookupOutputSchema(schemaLookupDTO, requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof lookupOutputSchema>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type LookupOutputSchemaQueryResult = NonNullable<
  Awaited<ReturnType<typeof lookupOutputSchema>>
>;
export type LookupOutputSchemaQueryError = ErrorType<unknown>;

export const useLookupOutputSchema = <
  TData = Awaited<ReturnType<typeof lookupOutputSchema>>,
  TError = ErrorType<unknown>,
>(
  schemaLookupDTO: SchemaLookupDTO,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof lookupOutputSchema>>, TError, TData>>;
    request?: SecondParameter<typeof executeFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getLookupOutputSchemaQueryOptions(schemaLookupDTO, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const prefetchLookupOutputSchema = async <
  TData = Awaited<ReturnType<typeof lookupOutputSchema>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  schemaLookupDTO: SchemaLookupDTO,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof lookupOutputSchema>>, TError, TData>>;
    request?: SecondParameter<typeof executeFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getLookupOutputSchemaQueryOptions(schemaLookupDTO, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const lookupOutputSchemaByWorkflowId = (
  workflowId: string,
  lookupOutputSchemaByWorkflowIdBody: string[],
  options?: SecondParameter<typeof executeFetch>,
) => {
  return executeFetch<SchemaMap>(
    {
      url: `/api/workflow-builder/lookup/output-schema/${encodeURIComponent(String(workflowId))}`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: lookupOutputSchemaByWorkflowIdBody,
    },
    options,
  );
};

export const getLookupOutputSchemaByWorkflowIdMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof lookupOutputSchemaByWorkflowId>>,
    TError,
    { workflowId: string; data: string[] },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof lookupOutputSchemaByWorkflowId>>,
  TError,
  { workflowId: string; data: string[] },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof lookupOutputSchemaByWorkflowId>>,
    { workflowId: string; data: string[] }
  > = (props) => {
    const { workflowId, data } = props ?? {};

    return lookupOutputSchemaByWorkflowId(workflowId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type LookupOutputSchemaByWorkflowIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof lookupOutputSchemaByWorkflowId>>
>;
export type LookupOutputSchemaByWorkflowIdMutationBody = string[];
export type LookupOutputSchemaByWorkflowIdMutationError = ErrorType<unknown>;

export const useLookupOutputSchemaByWorkflowId = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof lookupOutputSchemaByWorkflowId>>,
    TError,
    { workflowId: string; data: string[] },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof lookupOutputSchemaByWorkflowId>>,
  TError,
  { workflowId: string; data: string[] },
  TContext
> => {
  const mutationOptions = getLookupOutputSchemaByWorkflowIdMutationOptions(options);

  return useMutation(mutationOptions);
};
export const lookupResources = (
  lookupResourcesBody: string[],
  options?: SecondParameter<typeof executeFetch>,
) => {
  return executeFetch<NodeBuilderResourceMap>(
    {
      url: `/api/workflow-builder/lookup/resources`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: lookupResourcesBody,
    },
    options,
  );
};

export const getLookupResourcesMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof lookupResources>>,
    TError,
    { data: string[] },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof lookupResources>>,
  TError,
  { data: string[] },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof lookupResources>>,
    { data: string[] }
  > = (props) => {
    const { data } = props ?? {};

    return lookupResources(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type LookupResourcesMutationResult = NonNullable<
  Awaited<ReturnType<typeof lookupResources>>
>;
export type LookupResourcesMutationBody = string[];
export type LookupResourcesMutationError = ErrorType<unknown>;

export const useLookupResources = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof lookupResources>>,
    TError,
    { data: string[] },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof lookupResources>>,
  TError,
  { data: string[] },
  TContext
> => {
  const mutationOptions = getLookupResourcesMutationOptions(options);

  return useMutation(mutationOptions);
};
export const lookupRunVariablesSchema = (
  options?: SecondParameter<typeof executeFetch>,
  signal?: AbortSignal,
) => {
  return executeFetch<Schema>(
    { url: `/api/workflow-builder/lookup/run-variables-schema`, method: 'GET', signal },
    options,
  );
};

export const getLookupRunVariablesSchemaQueryKey = () => {
  return [`/api/workflow-builder/lookup/run-variables-schema`] as const;
};

export const getLookupRunVariablesSchemaQueryOptions = <
  TData = Awaited<ReturnType<typeof lookupRunVariablesSchema>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof lookupRunVariablesSchema>>, TError, TData>
  >;
  request?: SecondParameter<typeof executeFetch>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getLookupRunVariablesSchemaQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof lookupRunVariablesSchema>>> = ({
    signal,
  }) => lookupRunVariablesSchema(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof lookupRunVariablesSchema>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type LookupRunVariablesSchemaQueryResult = NonNullable<
  Awaited<ReturnType<typeof lookupRunVariablesSchema>>
>;
export type LookupRunVariablesSchemaQueryError = ErrorType<unknown>;

export const useLookupRunVariablesSchema = <
  TData = Awaited<ReturnType<typeof lookupRunVariablesSchema>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof lookupRunVariablesSchema>>, TError, TData>
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getLookupRunVariablesSchemaQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const prefetchLookupRunVariablesSchema = async <
  TData = Awaited<ReturnType<typeof lookupRunVariablesSchema>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof lookupRunVariablesSchema>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getLookupRunVariablesSchemaQueryOptions(options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const lookupRuntimeConnectionsSchema = (
  workflowId: string,
  options?: SecondParameter<typeof executeFetch>,
  signal?: AbortSignal,
) => {
  return executeFetch<Schema>(
    {
      url: `/api/workflow-builder/lookup/runtime-connections-schema/${encodeURIComponent(String(workflowId))}`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getLookupRuntimeConnectionsSchemaQueryKey = (workflowId: string) => {
  return [`/api/workflow-builder/lookup/runtime-connections-schema/${workflowId}`] as const;
};

export const getLookupRuntimeConnectionsSchemaQueryOptions = <
  TData = Awaited<ReturnType<typeof lookupRuntimeConnectionsSchema>>,
  TError = ErrorType<unknown>,
>(
  workflowId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof lookupRuntimeConnectionsSchema>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getLookupRuntimeConnectionsSchemaQueryKey(workflowId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof lookupRuntimeConnectionsSchema>>> = ({
    signal,
  }) => lookupRuntimeConnectionsSchema(workflowId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!workflowId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof lookupRuntimeConnectionsSchema>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type LookupRuntimeConnectionsSchemaQueryResult = NonNullable<
  Awaited<ReturnType<typeof lookupRuntimeConnectionsSchema>>
>;
export type LookupRuntimeConnectionsSchemaQueryError = ErrorType<unknown>;

export const useLookupRuntimeConnectionsSchema = <
  TData = Awaited<ReturnType<typeof lookupRuntimeConnectionsSchema>>,
  TError = ErrorType<unknown>,
>(
  workflowId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof lookupRuntimeConnectionsSchema>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getLookupRuntimeConnectionsSchemaQueryOptions(workflowId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const prefetchLookupRuntimeConnectionsSchema = async <
  TData = Awaited<ReturnType<typeof lookupRuntimeConnectionsSchema>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  workflowId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof lookupRuntimeConnectionsSchema>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getLookupRuntimeConnectionsSchemaQueryOptions(workflowId, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const lookupStartInputs = (
  workflowId: string,
  options?: SecondParameter<typeof executeFetch>,
  signal?: AbortSignal,
) => {
  return executeFetch<StringObjectMap>(
    {
      url: `/api/workflow-builder/lookup/start-inputs/${encodeURIComponent(String(workflowId))}`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getLookupStartInputsQueryKey = (workflowId: string) => {
  return [`/api/workflow-builder/lookup/start-inputs/${workflowId}`] as const;
};

export const getLookupStartInputsQueryOptions = <
  TData = Awaited<ReturnType<typeof lookupStartInputs>>,
  TError = ErrorType<unknown>,
>(
  workflowId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof lookupStartInputs>>, TError, TData>>;
    request?: SecondParameter<typeof executeFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getLookupStartInputsQueryKey(workflowId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof lookupStartInputs>>> = ({ signal }) =>
    lookupStartInputs(workflowId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!workflowId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof lookupStartInputs>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type LookupStartInputsQueryResult = NonNullable<
  Awaited<ReturnType<typeof lookupStartInputs>>
>;
export type LookupStartInputsQueryError = ErrorType<unknown>;

export const useLookupStartInputs = <
  TData = Awaited<ReturnType<typeof lookupStartInputs>>,
  TError = ErrorType<unknown>,
>(
  workflowId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof lookupStartInputs>>, TError, TData>>;
    request?: SecondParameter<typeof executeFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getLookupStartInputsQueryOptions(workflowId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const prefetchLookupStartInputs = async <
  TData = Awaited<ReturnType<typeof lookupStartInputs>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  workflowId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof lookupStartInputs>>, TError, TData>>;
    request?: SecondParameter<typeof executeFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getLookupStartInputsQueryOptions(workflowId, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const lookupStartNodeResource = (
  workflowId: string,
  options?: SecondParameter<typeof executeFetch>,
  signal?: AbortSignal,
) => {
  return executeFetch<NodeBuilderResource>(
    {
      url: `/api/workflow-builder/lookup/start-node-resource/${encodeURIComponent(String(workflowId))}`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getLookupStartNodeResourceQueryKey = (workflowId: string) => {
  return [`/api/workflow-builder/lookup/start-node-resource/${workflowId}`] as const;
};

export const getLookupStartNodeResourceQueryOptions = <
  TData = Awaited<ReturnType<typeof lookupStartNodeResource>>,
  TError = ErrorType<unknown>,
>(
  workflowId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof lookupStartNodeResource>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getLookupStartNodeResourceQueryKey(workflowId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof lookupStartNodeResource>>> = ({
    signal,
  }) => lookupStartNodeResource(workflowId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!workflowId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof lookupStartNodeResource>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type LookupStartNodeResourceQueryResult = NonNullable<
  Awaited<ReturnType<typeof lookupStartNodeResource>>
>;
export type LookupStartNodeResourceQueryError = ErrorType<unknown>;

export const useLookupStartNodeResource = <
  TData = Awaited<ReturnType<typeof lookupStartNodeResource>>,
  TError = ErrorType<unknown>,
>(
  workflowId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof lookupStartNodeResource>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getLookupStartNodeResourceQueryOptions(workflowId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const prefetchLookupStartNodeResource = async <
  TData = Awaited<ReturnType<typeof lookupStartNodeResource>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  workflowId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof lookupStartNodeResource>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getLookupStartNodeResourceQueryOptions(workflowId, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const lookupStartSchema = (
  workflowId: string,
  options?: SecondParameter<typeof executeFetch>,
  signal?: AbortSignal,
) => {
  return executeFetch<Schema>(
    {
      url: `/api/workflow-builder/lookup/start-schema/${encodeURIComponent(String(workflowId))}`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getLookupStartSchemaQueryKey = (workflowId: string) => {
  return [`/api/workflow-builder/lookup/start-schema/${workflowId}`] as const;
};

export const getLookupStartSchemaQueryOptions = <
  TData = Awaited<ReturnType<typeof lookupStartSchema>>,
  TError = ErrorType<unknown>,
>(
  workflowId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof lookupStartSchema>>, TError, TData>>;
    request?: SecondParameter<typeof executeFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getLookupStartSchemaQueryKey(workflowId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof lookupStartSchema>>> = ({ signal }) =>
    lookupStartSchema(workflowId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!workflowId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof lookupStartSchema>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type LookupStartSchemaQueryResult = NonNullable<
  Awaited<ReturnType<typeof lookupStartSchema>>
>;
export type LookupStartSchemaQueryError = ErrorType<unknown>;

export const useLookupStartSchema = <
  TData = Awaited<ReturnType<typeof lookupStartSchema>>,
  TError = ErrorType<unknown>,
>(
  workflowId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof lookupStartSchema>>, TError, TData>>;
    request?: SecondParameter<typeof executeFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getLookupStartSchemaQueryOptions(workflowId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const prefetchLookupStartSchema = async <
  TData = Awaited<ReturnType<typeof lookupStartSchema>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  workflowId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof lookupStartSchema>>, TError, TData>>;
    request?: SecondParameter<typeof executeFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getLookupStartSchemaQueryOptions(workflowId, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const lookupUserVariableSchema = (
  options?: SecondParameter<typeof executeFetch>,
  signal?: AbortSignal,
) => {
  return executeFetch<Schema>(
    { url: `/api/workflow-builder/lookup/user-variables-schema`, method: 'GET', signal },
    options,
  );
};

export const getLookupUserVariableSchemaQueryKey = () => {
  return [`/api/workflow-builder/lookup/user-variables-schema`] as const;
};

export const getLookupUserVariableSchemaQueryOptions = <
  TData = Awaited<ReturnType<typeof lookupUserVariableSchema>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof lookupUserVariableSchema>>, TError, TData>
  >;
  request?: SecondParameter<typeof executeFetch>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getLookupUserVariableSchemaQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof lookupUserVariableSchema>>> = ({
    signal,
  }) => lookupUserVariableSchema(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof lookupUserVariableSchema>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type LookupUserVariableSchemaQueryResult = NonNullable<
  Awaited<ReturnType<typeof lookupUserVariableSchema>>
>;
export type LookupUserVariableSchemaQueryError = ErrorType<unknown>;

export const useLookupUserVariableSchema = <
  TData = Awaited<ReturnType<typeof lookupUserVariableSchema>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof lookupUserVariableSchema>>, TError, TData>
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getLookupUserVariableSchemaQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const prefetchLookupUserVariableSchema = async <
  TData = Awaited<ReturnType<typeof lookupUserVariableSchema>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof lookupUserVariableSchema>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getLookupUserVariableSchemaQueryOptions(options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const automapNode = (
  nodeAutoMapRequest: NodeAutoMapRequest,
  options?: SecondParameter<typeof executeFetch>,
) => {
  return executeFetch<NodeAutoMapResponse>(
    {
      url: `/api/workflow-builder/node/automap`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: nodeAutoMapRequest,
    },
    options,
  );
};

export const getAutomapNodeMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof automapNode>>,
    TError,
    { data: NodeAutoMapRequest },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof automapNode>>,
  TError,
  { data: NodeAutoMapRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof automapNode>>,
    { data: NodeAutoMapRequest }
  > = (props) => {
    const { data } = props ?? {};

    return automapNode(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AutomapNodeMutationResult = NonNullable<Awaited<ReturnType<typeof automapNode>>>;
export type AutomapNodeMutationBody = NodeAutoMapRequest;
export type AutomapNodeMutationError = ErrorType<unknown>;

export const useAutomapNode = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof automapNode>>,
    TError,
    { data: NodeAutoMapRequest },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof automapNode>>,
  TError,
  { data: NodeAutoMapRequest },
  TContext
> => {
  const mutationOptions = getAutomapNodeMutationOptions(options);

  return useMutation(mutationOptions);
};
export const findResourcesForNodes = (
  findNodeBuilderResourcesRequest: FindNodeBuilderResourcesRequest,
  options?: SecondParameter<typeof executeFetch>,
) => {
  return executeFetch<NodeBuilderResourceListMap>(
    {
      url: `/api/workflow-builder/node/resources`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: findNodeBuilderResourcesRequest,
    },
    options,
  );
};

export const getFindResourcesForNodesQueryKey = (
  findNodeBuilderResourcesRequest: FindNodeBuilderResourcesRequest,
) => {
  return [`/api/workflow-builder/node/resources`, findNodeBuilderResourcesRequest] as const;
};

export const getFindResourcesForNodesQueryOptions = <
  TData = Awaited<ReturnType<typeof findResourcesForNodes>>,
  TError = ErrorType<unknown>,
>(
  findNodeBuilderResourcesRequest: FindNodeBuilderResourcesRequest,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof findResourcesForNodes>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getFindResourcesForNodesQueryKey(findNodeBuilderResourcesRequest);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof findResourcesForNodes>>> = () =>
    findResourcesForNodes(findNodeBuilderResourcesRequest, requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof findResourcesForNodes>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FindResourcesForNodesQueryResult = NonNullable<
  Awaited<ReturnType<typeof findResourcesForNodes>>
>;
export type FindResourcesForNodesQueryError = ErrorType<unknown>;

export const useFindResourcesForNodes = <
  TData = Awaited<ReturnType<typeof findResourcesForNodes>>,
  TError = ErrorType<unknown>,
>(
  findNodeBuilderResourcesRequest: FindNodeBuilderResourcesRequest,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof findResourcesForNodes>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFindResourcesForNodesQueryOptions(
    findNodeBuilderResourcesRequest,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const prefetchFindResourcesForNodes = async <
  TData = Awaited<ReturnType<typeof findResourcesForNodes>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  findNodeBuilderResourcesRequest: FindNodeBuilderResourcesRequest,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof findResourcesForNodes>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getFindResourcesForNodesQueryOptions(
    findNodeBuilderResourcesRequest,
    options,
  );

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const findNodeSuggestions = (
  nodeAutoMapRequest: NodeAutoMapRequest,
  options?: SecondParameter<typeof executeFetch>,
) => {
  return executeFetch<Schema>(
    {
      url: `/api/workflow-builder/node/suggestions`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: nodeAutoMapRequest,
    },
    options,
  );
};

export const getFindNodeSuggestionsMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof findNodeSuggestions>>,
    TError,
    { data: NodeAutoMapRequest },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof findNodeSuggestions>>,
  TError,
  { data: NodeAutoMapRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof findNodeSuggestions>>,
    { data: NodeAutoMapRequest }
  > = (props) => {
    const { data } = props ?? {};

    return findNodeSuggestions(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type FindNodeSuggestionsMutationResult = NonNullable<
  Awaited<ReturnType<typeof findNodeSuggestions>>
>;
export type FindNodeSuggestionsMutationBody = NodeAutoMapRequest;
export type FindNodeSuggestionsMutationError = ErrorType<unknown>;

export const useFindNodeSuggestions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof findNodeSuggestions>>,
    TError,
    { data: NodeAutoMapRequest },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof findNodeSuggestions>>,
  TError,
  { data: NodeAutoMapRequest },
  TContext
> => {
  const mutationOptions = getFindNodeSuggestionsMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getNode = (
  name: string,
  options?: SecondParameter<typeof executeFetch>,
  signal?: AbortSignal,
) => {
  return executeFetch<NodeBuilder>(
    {
      url: `/api/workflow-builder/node/${encodeURIComponent(String(name))}`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getGetNodeQueryKey = (name: string) => {
  return [`/api/workflow-builder/node/${name}`] as const;
};

export const getGetNodeQueryOptions = <
  TData = Awaited<ReturnType<typeof getNode>>,
  TError = ErrorType<unknown>,
>(
  name: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getNode>>, TError, TData>>;
    request?: SecondParameter<typeof executeFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetNodeQueryKey(name);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getNode>>> = ({ signal }) =>
    getNode(name, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!name, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getNode>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetNodeQueryResult = NonNullable<Awaited<ReturnType<typeof getNode>>>;
export type GetNodeQueryError = ErrorType<unknown>;

export const useGetNode = <
  TData = Awaited<ReturnType<typeof getNode>>,
  TError = ErrorType<unknown>,
>(
  name: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getNode>>, TError, TData>>;
    request?: SecondParameter<typeof executeFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetNodeQueryOptions(name, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const prefetchGetNode = async <
  TData = Awaited<ReturnType<typeof getNode>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  name: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getNode>>, TError, TData>>;
    request?: SecondParameter<typeof executeFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getGetNodeQueryOptions(name, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const findResourcesForNode = (
  name: string,
  params?: FindResourcesForNodeParams,
  options?: SecondParameter<typeof executeFetch>,
  signal?: AbortSignal,
) => {
  return executeFetch<NodeBuilderResourceHits>(
    {
      url: `/api/workflow-builder/node/${encodeURIComponent(String(name))}/resources`,
      method: 'GET',
      params,
      signal,
    },
    options,
  );
};

export const getFindResourcesForNodeQueryKey = (
  name: string,
  params?: FindResourcesForNodeParams,
) => {
  return [`/api/workflow-builder/node/${name}/resources`, ...(params ? [params] : [])] as const;
};

export const getFindResourcesForNodeQueryOptions = <
  TData = Awaited<ReturnType<typeof findResourcesForNode>>,
  TError = ErrorType<unknown>,
>(
  name: string,
  params?: FindResourcesForNodeParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof findResourcesForNode>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getFindResourcesForNodeQueryKey(name, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof findResourcesForNode>>> = ({ signal }) =>
    findResourcesForNode(name, params, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!name, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof findResourcesForNode>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FindResourcesForNodeQueryResult = NonNullable<
  Awaited<ReturnType<typeof findResourcesForNode>>
>;
export type FindResourcesForNodeQueryError = ErrorType<unknown>;

export const useFindResourcesForNode = <
  TData = Awaited<ReturnType<typeof findResourcesForNode>>,
  TError = ErrorType<unknown>,
>(
  name: string,
  params?: FindResourcesForNodeParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof findResourcesForNode>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFindResourcesForNodeQueryOptions(name, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const prefetchFindResourcesForNode = async <
  TData = Awaited<ReturnType<typeof findResourcesForNode>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  name: string,
  params?: FindResourcesForNodeParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof findResourcesForNode>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getFindResourcesForNodeQueryOptions(name, params, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const findResourceForNode = (
  nodeName: string,
  resourceName: string,
  options?: SecondParameter<typeof executeFetch>,
  signal?: AbortSignal,
) => {
  return executeFetch<NodeBuilderResource>(
    {
      url: `/api/workflow-builder/node/${encodeURIComponent(String(nodeName))}/resource/${encodeURIComponent(String(resourceName))}`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getFindResourceForNodeQueryKey = (nodeName: string, resourceName: string) => {
  return [`/api/workflow-builder/node/${nodeName}/resource/${resourceName}`] as const;
};

export const getFindResourceForNodeQueryOptions = <
  TData = Awaited<ReturnType<typeof findResourceForNode>>,
  TError = ErrorType<unknown>,
>(
  nodeName: string,
  resourceName: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof findResourceForNode>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getFindResourceForNodeQueryKey(nodeName, resourceName);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof findResourceForNode>>> = ({ signal }) =>
    findResourceForNode(nodeName, resourceName, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(nodeName && resourceName),
    ...queryOptions,
  } as UseQueryOptions<Awaited<ReturnType<typeof findResourceForNode>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type FindResourceForNodeQueryResult = NonNullable<
  Awaited<ReturnType<typeof findResourceForNode>>
>;
export type FindResourceForNodeQueryError = ErrorType<unknown>;

export const useFindResourceForNode = <
  TData = Awaited<ReturnType<typeof findResourceForNode>>,
  TError = ErrorType<unknown>,
>(
  nodeName: string,
  resourceName: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof findResourceForNode>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFindResourceForNodeQueryOptions(nodeName, resourceName, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const prefetchFindResourceForNode = async <
  TData = Awaited<ReturnType<typeof findResourceForNode>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  nodeName: string,
  resourceName: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof findResourceForNode>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getFindResourceForNodeQueryOptions(nodeName, resourceName, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getNodes = (
  params?: GetNodesParams,
  options?: SecondParameter<typeof executeFetch>,
  signal?: AbortSignal,
) => {
  return executeFetch<NodeBuilderHits>(
    { url: `/api/workflow-builder/nodes`, method: 'GET', params, signal },
    options,
  );
};

export const getGetNodesQueryKey = (params?: GetNodesParams) => {
  return [`/api/workflow-builder/nodes`, ...(params ? [params] : [])] as const;
};

export const getGetNodesQueryOptions = <
  TData = Awaited<ReturnType<typeof getNodes>>,
  TError = ErrorType<unknown>,
>(
  params?: GetNodesParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getNodes>>, TError, TData>>;
    request?: SecondParameter<typeof executeFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetNodesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getNodes>>> = ({ signal }) =>
    getNodes(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getNodes>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetNodesQueryResult = NonNullable<Awaited<ReturnType<typeof getNodes>>>;
export type GetNodesQueryError = ErrorType<unknown>;

export const useGetNodes = <
  TData = Awaited<ReturnType<typeof getNodes>>,
  TError = ErrorType<unknown>,
>(
  params?: GetNodesParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getNodes>>, TError, TData>>;
    request?: SecondParameter<typeof executeFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetNodesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const prefetchGetNodes = async <
  TData = Awaited<ReturnType<typeof getNodes>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  params?: GetNodesParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getNodes>>, TError, TData>>;
    request?: SecondParameter<typeof executeFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getGetNodesQueryOptions(params, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getWebhookTriggerUrl = (
  params?: GetWebhookTriggerUrlParams,
  options?: SecondParameter<typeof executeFetch>,
  signal?: AbortSignal,
) => {
  return executeFetch<StringObjectMap>(
    { url: `/api/workflow-builder/webhook-trigger-url`, method: 'GET', params, signal },
    options,
  );
};

export const getGetWebhookTriggerUrlQueryKey = (params?: GetWebhookTriggerUrlParams) => {
  return [`/api/workflow-builder/webhook-trigger-url`, ...(params ? [params] : [])] as const;
};

export const getGetWebhookTriggerUrlQueryOptions = <
  TData = Awaited<ReturnType<typeof getWebhookTriggerUrl>>,
  TError = ErrorType<unknown>,
>(
  params?: GetWebhookTriggerUrlParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getWebhookTriggerUrl>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetWebhookTriggerUrlQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getWebhookTriggerUrl>>> = ({ signal }) =>
    getWebhookTriggerUrl(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getWebhookTriggerUrl>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetWebhookTriggerUrlQueryResult = NonNullable<
  Awaited<ReturnType<typeof getWebhookTriggerUrl>>
>;
export type GetWebhookTriggerUrlQueryError = ErrorType<unknown>;

export const useGetWebhookTriggerUrl = <
  TData = Awaited<ReturnType<typeof getWebhookTriggerUrl>>,
  TError = ErrorType<unknown>,
>(
  params?: GetWebhookTriggerUrlParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getWebhookTriggerUrl>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetWebhookTriggerUrlQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const prefetchGetWebhookTriggerUrl = async <
  TData = Awaited<ReturnType<typeof getWebhookTriggerUrl>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  params?: GetWebhookTriggerUrlParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getWebhookTriggerUrl>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getGetWebhookTriggerUrlQueryOptions(params, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getWebhookUrl = (
  params?: GetWebhookUrlParams,
  options?: SecondParameter<typeof executeFetch>,
  signal?: AbortSignal,
) => {
  return executeFetch<StringObjectMap>(
    { url: `/api/workflow-builder/webhook-url`, method: 'GET', params, signal },
    options,
  );
};

export const getGetWebhookUrlQueryKey = (params?: GetWebhookUrlParams) => {
  return [`/api/workflow-builder/webhook-url`, ...(params ? [params] : [])] as const;
};

export const getGetWebhookUrlQueryOptions = <
  TData = Awaited<ReturnType<typeof getWebhookUrl>>,
  TError = ErrorType<unknown>,
>(
  params?: GetWebhookUrlParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getWebhookUrl>>, TError, TData>>;
    request?: SecondParameter<typeof executeFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetWebhookUrlQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getWebhookUrl>>> = ({ signal }) =>
    getWebhookUrl(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getWebhookUrl>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetWebhookUrlQueryResult = NonNullable<Awaited<ReturnType<typeof getWebhookUrl>>>;
export type GetWebhookUrlQueryError = ErrorType<unknown>;

export const useGetWebhookUrl = <
  TData = Awaited<ReturnType<typeof getWebhookUrl>>,
  TError = ErrorType<unknown>,
>(
  params?: GetWebhookUrlParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getWebhookUrl>>, TError, TData>>;
    request?: SecondParameter<typeof executeFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetWebhookUrlQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const prefetchGetWebhookUrl = async <
  TData = Awaited<ReturnType<typeof getWebhookUrl>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  params?: GetWebhookUrlParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getWebhookUrl>>, TError, TData>>;
    request?: SecondParameter<typeof executeFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getGetWebhookUrlQueryOptions(params, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};
